<template>
    <div>
        <SectionIntro />
        <BaseSectionNetworkValue />
        <SectionHelp />
        <SectionWaysToEarn />
        <SectionFriends />
        <SectionC2A />
    </div>
</template>

<script>
import { ROUTE } from '@/enums'
import { BaseSectionNetworkValue } from '@/components'
import {
    SectionIntro,
    SectionHelp,
    SectionWaysToEarn,
    SectionFriends,
    SectionC2A,
} from './components'

export default {
    components: {
        BaseSectionNetworkValue,
        SectionIntro,
        SectionHelp,
        SectionWaysToEarn,
        SectionFriends,
        SectionC2A,
    },

    data: () => ({ ROUTE }),
}
</script>
